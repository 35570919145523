import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from 'api/utils';

const DISCOUNT_CODE_ARGS = {
  apps: 'AppType',
};

const LIST_ARGS = { ...omit(COMMON_LIST_ARGS, 'ids'), ...DISCOUNT_CODE_ARGS };

const commonFields = `
  id
  code
  status
  validUpto
  apps
`;

const GET = gql`
  query GetCoupon($id: ID!) {
    coupon(id: $id) {
      ${commonFields}
      planOverride {
        plan
        discount
        validity
      }
    }
  }
`;

const LIST = gql`
  query ListCoupons${toGqlVariables(LIST_ARGS)} {
    items: coupons${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        usedCount
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateCoupon($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation UpdateCoupon($input: UpdateCouponInput!) {
    updateCoupon(input: $input) {
      id
    }
  }
`;

export { GET, LIST, CREATE, UPDATE };
