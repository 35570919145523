import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { COMMON_LIST_ARGS, IMAGE_FIELDS, toGqlArgs, toGqlVariables } from 'api/utils';

const LIST_ARGS = {
  ...omit(COMMON_LIST_ARGS, 'status'),
  userId: 'ID',
  paper: 'PaperName',
  status: 'EvaluationStatus',
};

const commonFields = `
  id
  images {
    ${IMAGE_FIELDS}
  }
  resultPdf{
    ${IMAGE_FIELDS}
  }
  userId
  paper
  status
  message
`;

const GET = gql`
  query GetEvaluation($id: ID!) {
    evaluation(id: $id) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListEvaluations${toGqlVariables(LIST_ARGS)} {
    items: evaluations${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        createdAt
        updatedAt
        user {
          id
          name
        }
      }
      totalCount
    }
  }
`;

const APPROVE = gql`
  mutation ApproveEvaluation($id: ID!, $resultPdfId: ID, $action: String!) {
    approveEvaluation(id: $id, resultPdfId: $resultPdfId, action: $action) {
      success
      message
    }
  }
`;

const REJECT = gql`
  mutation RejectEvaluation($id: ID!, $message: String) {
    rejectEvaluation(id: $id, message: $message) {
      success
    }
  }
`;

export { GET, LIST, APPROVE, REJECT };
