import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
} from '../utils';
import { listFieldsWithParent as questionListFields } from './question';

const sponsorData = `
  logoId
  logo {
    url
    path
  }
  bannerId
  banner {
    url
    path
  }
`;
const sponsorsFields = `
  sponsors {
    title {
      ${sponsorData}
    }
    knowledge {
      ${sponsorData}
    }
    academic {
      ${sponsorData}
    }
    associate {
      ${sponsorData}
    }
  }
`;

const publicFields = `
  isPublic
  slug
  webDescription
  externalVideos {
    title
    url
  }
  seo {
    title
    description
    keywords
  }
`;

const testSettings = `
  id
  name { ${LANGUAGE_FIELDS} }
  status
  totalTime
  totalQuestions
  totalMarks
  sectionSwitchable
  resumable
  calculator
  contentLanguages
  type
  instructionTemplateId
  year
  date
  shift
  tags
  publishDate
  examIds
  topicId
  subjectId
  bookId
  featured
  featuredTill
  availableTill
  resultAfter
  shortUrl
  chapterId
  subtopicId
  paid
  difficulty
  ${sponsorsFields}
  ${publicFields}
`;

const sections = `
  sections {
    name { ${LANGUAGE_FIELDS} }
    totalTime
    totalQuestions
    totalMarks
    instructionTemplateId
    maxAttemptableQuestions
    shuffleQuestions
    questions {
      ${questionListFields}
      positiveMarks
      negativeMarks
    }
  }
`;

const TEST_ARGS = {
  status: 'Status2 = null',
  publishedOnly: 'Boolean = false',
  publishDate: '[DateTime!]',
  type: 'TestTypes',
  examIds: '[ID!]',
  topicId: 'ID',
  bookId: 'ID',
  subjectId: 'ID',
  questionId: 'ID',
  year: 'Int',
  includeSpecialTypes: 'Boolean',
  includeScholarshipType: 'Boolean',
  chapterId: 'ID',
  subtopicId: 'ID',
  tag: 'TestTags',
  paid: 'Boolean',
  difficulty: 'Difficulty',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...TEST_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...TEST_ARGS };

const GET = gql`
  query GetTest($testId: ID!) {
    test(id: $testId) {
      ${testSettings}
      ${sections}
    }
  }
`;

const LIST = gql`
  query ListTests${toGqlVariables(LIST_ARGS)} {
    items: tests${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        name { ${LANGUAGE_FIELDS} }
        status
        type
        totalTime
        totalQuestions
        totalMarks
        contentLanguages
        exams {
          id
          shortName { ${LANGUAGE_FIELDS} }
        }
        topic {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        book {
          id
          name
        }
        subject {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        attemptStats {
          complete
          pause
          progress
        }
        paid
        publishDate
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateTest($input: CreateTestInput!) {
    createTest(input: $input) {
      id
      name { ${LANGUAGE_FIELDS} }
    }
  }
`;

const CLONE = gql`
  mutation CloneTest($testId: ID!) {
    cloneTest(testId: $testId) {
      id
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation UpdateTestSettings($input: UpdateTestInput!) {
    updateTestSettings: updateTest(input: $input) {
      ${testSettings}
    }
  }
`;

const UPDATE_SECTIONS = gql`
  mutation UpdateTestSections($input: UpdateTestInput!) {
    updateTest(input: $input) {
      ${sections}
    }
  }
`;

const CREATE_BY_CSV = gql`
  mutation CreateTestQuestionsByCsv($input: CreateTestQuestionsByCsvInput!) {
    createTestQuestionsByCsv(input: $input) {
      data{
        ${sections}
      }
      errors
    }
  }
`;

const ADD_QUESTIONS = gql`
  mutation AddQuestions($input: CreateTestQuestionsInput!) {
    createTestQuestions(input: $input) {
      ${sections}
    }
}`;

const SEARCH = gql`
  query SearchTests${toGqlVariables(SEARCH_ARGS)} {
    items: tests${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name {
          en
        }
      }
    }
  }
`;

const ANALYZE_TEST = gql`
  query analyzeTest($id: ID!) {
    test(id: $id) {
      id
      name { ${LANGUAGE_FIELDS} }
      status
      totalTime
      totalQuestions
      totalMarks
      sectionSwitchable
      contentLanguages
      sections {
        name { ${LANGUAGE_FIELDS} }
        totalTime
        totalQuestions
        totalMarks
        shuffleQuestions
        questions {
          positiveMarks
          negativeMarks
        }
      }
    }
  }
`;

const GENERATE_TEST = gql`
  mutation generateTest($input: GenerateTestInput!) {
    generateTest(input: $input) {
      id
    }
  }
`;

export {
  GET,
  LIST,
  CREATE,
  CLONE,
  UPDATE_SETTINGS,
  UPDATE_SECTIONS,
  ADD_QUESTIONS,
  SEARCH,
  CREATE_BY_CSV,
  ANALYZE_TEST,
  GENERATE_TEST,
};
